import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { breakpoint_md, breakpoint_xl } from "../../mixins";

export const TitleWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 13px;
  padding-top: 60px;
`;

// StudentResults
export const StudentResultsWrapper = styled(Box)`
    padding-bottom: 60px;
    .swiper {
        height: 100%;
    }
    .swiper-pagination-bullets {
        display: none;
    }
    @media screen and (min-width: ${breakpoint_md}) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        gap: 20px;
        .swiper {
            height: 500px;
        }
    }
`

export const StudentList = styled.div`
  min-width: 200px;
  padding-bottom: 15px;
  @media screen and (min-width: ${breakpoint_md}) {
    align-self: stretch;
    padding-bottom: 0;
  }
  @media screen and (min-width: ${breakpoint_xl}) {
    min-width: 367px;
  }
`;

export const CustomNavigation = styled.div`
  position: relative;
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    color: #0000006c;
    --swiper-navigation-size: 25px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    color: #aa233b;
  }
  @media (min-width: ${breakpoint_md}) {
    .swiper-button-prev {
      left: 45%;
      top: 20px;
      transform: rotate(90deg);
    }
    .swiper-button-next {
      top: 95%;
      left: 45%;
      bottom: 20px;
      transform: rotate(90deg);
    }
  }
`;

export const StudentBox = styled(Box)<{ isactive: string }>`
  width: calc(100% - 20px);
  border-radius: 10px;
  height: 56px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  transition: all 0.3s ease-in-out;
  background-color: ${({ isactive }) => (isactive === 'true' ? "#AA233B" : "#EBECED")};
  color: ${({ isactive }) => (isactive === 'true' ? "#fff" : "#000")};
`;
export const StudentBoxImage = styled.img`
  width: 46px;
  height: 46px;
  display: inline-block;
  border-radius: 50%;
`;

export const StudentBoxName = styled(Box)`
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.28px;
  }
  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.2px;
    letter-spacing: -0.24px;
    opacity: 0.5;
  }
`;

export const StudentImgInfo = styled(Box)`
    @media screen and (min-width: ${breakpoint_md}) {
        display: flex;
        gap: 20px;
        justify-content: stretch;
    }
`;
export const StudentImage = styled(Box)`
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding-bottom: 15px;
    img {
        flex: 1;
        max-width: 440px;
        width: 100%;
        height: 490px;
        max-height: 490px;
        object-fit: cover;
        border-radius: 20px;
    }   
    @media screen and (min-width: ${breakpoint_md}) {
        padding-bottom: 0;
    }
`;

export const StudentTestimonial = styled(Box)`
    max-width: 100%;
    padding-bottom: 20px;

  @media screen and (min-width: ${breakpoint_md}) {
    max-width: 450px;
  }
`;

export const UniversityBox = styled.div`
  padding: 15px;
  border-radius: 10px;
  background: #ebeced;
  position: relative;
  margin-bottom: 10px;
`;
export const UniversityLogo = styled.img`
  max-width: 290px;
  width: 100%;
  margin: 0 auto;
  max-height: 250px;
`;

export const UniversityBoxInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UniversityBoxInfoText = styled.div`
  span {
    display: inline-block;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  p {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const UniversityBoxInfoIcon = styled.span`
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
  @media screen and (min-width: ${breakpoint_md}) {
    position: static;
  }
`;

export const FullTuition = styled.div`
  width: 100%;
  border-radius: 10px;
  background: #aa233b;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FullTuitionText = styled.div`
  color: #fff;
  font-style: normal;
  line-height: normal;
  span {
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 2px;
  }
  p {
    font-size: 20px;
    font-weight: 700;
  }
`;
export const SpinningIconWrapper = styled(Box)`
  align-self: flex-start;
  display: inline-block;
`;
