import React, { useEffect } from "react";
import { EnroleBox, EnroleContainer, EnroleInfo, EnroleLink, EnroleLinkPoint, EnroleLinks, EnroleWrapper, Iframe, } from "./styles";
import { useNavigate } from "react-router-dom";

const EnrolePage: React.FC = () => {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Validate the origin of the message for security
      if (event.origin !== "https://satashkent.s20.online") {
        return; // Ignore messages that don't come from the trusted domain
      }

      if (event.data === 'redirect') {
        navigate('/');
      }
    };

    // Add the event listener for 'message' events
    window.addEventListener('message', handleMessage as any);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('message', handleMessage as any);
    };
  }, [navigate]);

  return (
    <EnroleWrapper>
      <EnroleContainer>
        <EnroleBox>
          <EnroleInfo>
            <EnroleLinks>
              <EnroleLink to="/">Main page</EnroleLink>
              <EnroleLinkPoint />
              <EnroleLink to="/">Register page</EnroleLink>
            </EnroleLinks>
            <h5>
              Want to get into your dream university? Welcome to SATASHKENT!
            </h5>
          </EnroleInfo>
          <Iframe>
            <iframe
              loading="lazy"
              src="https://satashkent.s20.online/common/1/form/draw?id=3&amp;lead_source_id=3&amp;baseColor=205EDC&amp;borderRadius=8&amp;css=%2F%2Fcdn.alfacrm.pro%2Flead-form%2Fform.css"
              title="SATASHKENT"
            ></iframe>
          </Iframe>
        </EnroleBox>
      </EnroleContainer>
    </EnroleWrapper>
  );
};

export default EnrolePage;
