import { useState } from "react";
import {
  EnroleButton,
  HumbergerWrapper,
  NavbarListLi,
  NavbarListUl,
  NavbarListWrapper,
  ScrollLink,
} from "./styles";
import Hamburger from "hamburger-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SpinningIcon } from "../../global_styles/styles";
import { useEffect } from "react";

type NavbarListType = {
  full: boolean;
};

const NavbarList: React.FC<NavbarListType> = ({ full }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const navItems = [
    { label: "Results", id: "results" },
    { label: "Why us", id: "why-us" },
    { label: "Courses", id: "courses" },
    { label: "Platform", id: "personal-platform" },
    { label: "Teachers", id: "teachers" },
    { label: "FAQ", id: "faq" },
  ];
  const handleNavItemClick = (section: string) => {
    if (window.innerWidth <= 1024) {
      setOpen(false);
    }
    navigate(`/#${section}`);
  };
  useEffect(() => {
    if (location.hash) {
      const section = location.hash.replace("#", "");
      const element = document.getElementById(section);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div>
      {!full && (
        <HumbergerWrapper aria-label="add to shopping cart">
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </HumbergerWrapper>
      )}
      <NavbarListWrapper open={`${isOpen}`} full={`${full}`}>
        <NavbarListUl full={`${full}`}>
          {navItems.map((item) => (
            <NavbarListLi key={item.id} full={`${full}`}>
              <ScrollLink
                full={`${full}`}
                onClick={() => handleNavItemClick(item.id)}
                to={`#${item.id}`}
                smooth
                duration={800}
              >
                {item.label}
              </ScrollLink>
            </NavbarListLi>
          ))}
        </NavbarListUl>
        {!full && (
          <Link to="enrole">
            <EnroleButton
              variant="contained"
              onClick={() => setOpen(false)}
              color="secondary"
              endIcon={
                <SpinningIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                  >
                    <path
                      d="M21.6148 4.34676L17.317 0L10.3465 7.04996L9.28808 2.98434L3.41874 4.5522L5.94181 14.2189L1.91131 13.116L0.318359 19.0414L9.88678 21.6689L6.92538 24.6424L11.2125 29L18.2257 21.95L19.2948 26.0048L25.1642 24.4262L22.6197 14.7703L26.6502 15.8624L28.2325 9.92617L18.6854 7.33109L21.6361 4.34676H21.6148ZM16.6007 13.1376L21.6468 14.5108L16.6007 15.8732L17.9478 20.9769L14.2701 17.2356L10.5603 20.9661L11.9287 15.8624L6.904 14.4784L11.9287 13.1376L10.6031 8.06636L14.2594 11.7644L17.9478 8.03393L16.6007 13.116V13.1376Z"
                      fill="black"
                    />
                  </svg>
                </SpinningIcon>
              }
            >
              Enroll for a class
            </EnroleButton>
          </Link>
        )}
      </NavbarListWrapper>
    </div>
  );
};

export default NavbarList;
