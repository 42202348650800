import styled from "@emotion/styled";
import welcome_bg_image from "../media/welcome_bg_image.png";
import login_bg from "../media/login_bg.png";
import { Link } from "react-router-dom";
import { breakpoint_md, breakpoint_sm, breakpoint_xl, breakpoint_xxl } from "../mixins";

// Enrole Page 

export const EnroleWrapper = styled.div`
    width: 100%;
    background-image: url(${welcome_bg_image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (min-width:  ${breakpoint_xl}) {
        background-image: url(${login_bg});
        background-position: left top;
    }
`

export const EnroleContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 54px);
    padding: 75px 18px;
    @media (min-width:  ${breakpoint_xl}) {
        max-width: 1200px;
        margin: 0 auto;
        height: 100vh - 80px;
    }
`

export const EnroleBox = styled.div`
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(7.5px);
    padding: 23px 10px;
    @media screen and (min-width:  ${breakpoint_xl}) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        gap: 50px;
        padding: 30px;
    }
`

export const EnroleInfo = styled.div`
    margin-bottom: 10px;
    h5 {
        color: #FFF;
        font-size: 38px;
        line-height: 47.6px;
        font-style: normal;
        font-weight: 600;
    }
    @media screen and (min-width:  ${breakpoint_md}) {
        h5 {
            font-size: 48px;
            line-height: 57.6px;
            letter-spacing: -1.44px;
        }
    }
    @media screen and (min-width: ${breakpoint_xl}) {
        width: 50%;
    }
`

export const EnroleLinks = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
`

export const EnroleLink = styled(Link)`
    color: rgba(255, 255, 255, 0.60);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14.4px;
    letter-spacing: -0.36px;
`

export const EnroleLinkPoint = styled.div`
    width: 5px;
    height: 5px;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.60);
    border-radius: 50%;
`

export const Iframe = styled.div`
    width: 100%;
    @media screen and (min-width: ${breakpoint_xl}) {
        width: 50%;
    }
    iframe {
        display:block;
        width: 100%;
        height: 750px;
        border: none;
        @media screen and (min-width: ${breakpoint_sm}) {
            height: 730px;
            margin: 0 auto;
        } 
        @media screen and (min-width: ${breakpoint_md}) {
            width: 70%;
            height: 730px;
        } 
        @media screen and (min-width: ${breakpoint_xl}) {
            width: 100%;
            margin-top: 2px;
        }
        @media screen and (min-width: ${breakpoint_xxl}) {
            height: 745px;
            margin-top: 10px;
        }
    }
`

export const EnroleForm = styled.div`
    border-radius: 20px;
    background: #FFF;
    padding: 25px 10px;
    p {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        opacity: 0.5;
        padding-top: 15px;
    }
    h1 {
        font-size: 34px;
        padding: 0 30px;
        font-weight: 600;
        line-height: 40px;
    }
    @media screen and (min-width: ${breakpoint_md}) {
        h1 {
            font-size: 48px;
            line-height: 46px;
        }
    }
    @media screen and (min-width: ${breakpoint_xl}) {
        width: 50%;
        padding: 35px 10px
    }
`

export const EnroleFormElements = styled.div`
    padding: 10px 30px 35px 30px;
`


// Not Found Page

export const NotFoundWrapper = styled.div`
    width: 100%;
    background-image: url(${login_bg});
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
`

export const NotFoundContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 54px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    @media (min-width:  ${breakpoint_xl}) {
        max-width: 1200px;
        margin: 0 auto;
        height: 100vh - 80px;
    }
`

export const NotFoundBox = styled.div`
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(7.5px);
    max-width: 800px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-direction: column;
    padding: 46px 10px;
`

export const NotFoundImg = styled.img`
    width: 100%;
    max-width: 400px;
`