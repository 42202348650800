import {
  Container,
  DescriptionComponent,
  TitleComponent,
} from "../../global_styles/styles";
import OurCoursesSlick, { CourseType } from "./OurCourses";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TitleWrapper } from "./styles";
import "./index.css";

type Props = {
  title: string;
  description: string;
  courses: CourseType[];
};

const OurTeachers: React.FC<Props> = ({ title, description, courses }) => {
  const coursesComponent = <OurCoursesSlick title={title} courses={courses} />;
  return (
    <Container id="courses">
      <TitleWrapper>
        <TitleComponent>{title}</TitleComponent>
        <DescriptionComponent>{description}</DescriptionComponent>
      </TitleWrapper>
      {title === "Online courses" ? (
        <div className="course-wrapper">{coursesComponent}</div>
      ) : (
        coursesComponent
      )}
    </Container>
  );
};

export default OurTeachers;
