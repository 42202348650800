import { lazy } from "react";
import useQueryCustom from "../hooks/useQueryCustom";
const Welcome = lazy(() => import("../components/Welcome"));
const FAQ = lazy(() => import("../components/FAQ"));
const Personalized = lazy(() => import("../components/Personalized"));
const PersonalizedBottom = lazy(
  () => import("../components/PersonalizedBottom"),
);
const Collabs = lazy(() => import("../components/Collabs"));
const OurCourses = lazy(() => import("../components/OurCourses"));
const Impressive = lazy(() => import("../components/Impressive"));
const WhyUs = lazy(() => import("../components/WhyUs"));
const OurTeachersInfo = lazy(() => import("../components/OurTeachersInfo"));
const Students = lazy(() => import("../components/Students"));

const MainPage: React.FC = () => {
  const offline = useQueryCustom("/courses.json", "offline");
  const online = useQueryCustom("/courses.json", "online");

  return (
    <>
      <Welcome />
      <Students />
      <Impressive />
      <WhyUs />
      <OurCourses
        title="Offline courses"
        description="We have diverse types of courses, so you will find perfect fit course for yourself"
        courses={offline}
      />
      <OurCourses
        title="Online courses"
        description="We have diverse types of courses, so you will find perfect fit course for yourself"
        courses={online}
      />
      <Personalized />
      <PersonalizedBottom />
      <OurTeachersInfo />
      <FAQ />
      <Collabs />
    </>
  );
};

export default MainPage;
