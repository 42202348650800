import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { breakpoint_md } from "../../mixins";

export const PersonolizedBox = styled(Box)`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    flex-direction: column;
    gap: 12px;

    img {
        width: 100%;
        height: auto;
    }

    @media (min-width: ${breakpoint_md}) {
        flex-direction: row;
        gap: 28px;
    }
`;

export const TitleWrapper = styled(Box)`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 40px;
`;
