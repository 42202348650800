import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { PaddingBottom, TeacherBox, TeacherBoxInfo, TeacherBoxInfoContainer, TeacherBoxInfoHeader, TeacherBoxInfoHeaderScore, TeacherBoxInfoHeaderText } from './styles';
import useQueryCustom from '../../hooks/useQueryCustom';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

type TeacherType = {
    id: number | string;
    name: string;
    image: string;
    score_title: string,
    score: string,
    description: string;
}

const OurTeachersInfoSwiper: React.FC = () => {
    const teachers: TeacherType[] | [] = useQueryCustom('/teachers.json', 'teachers');
    return (
        <PaddingBottom>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                centeredSlides={true}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={
                    {
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        }
                    }
                }
                className="mySwiper"
            >
                {teachers.length &&
                    teachers.map((item: TeacherType) =>  (
                        <SwiperSlide key={item.id}>
                            <TeacherBox image={item.image}>
                                <TeacherBoxInfo className='teacher-box-info'>
                                    <TeacherBoxInfoContainer>
                                        <TeacherBoxInfoHeader>
                                            <TeacherBoxInfoHeaderText>{item.name}</TeacherBoxInfoHeaderText>
                                            <TeacherBoxInfoHeaderScore>
                                                <p>{item.score_title}</p>
                                                <span>{item.score}</span>
                                            </TeacherBoxInfoHeaderScore>
                                        </TeacherBoxInfoHeader>
                                        <p>{item.description}</p>
                                    </TeacherBoxInfoContainer>
                                </TeacherBoxInfo>
                                </TeacherBox>
                        </SwiperSlide>
                ))}
            </Swiper>
        </PaddingBottom>
    );
}

export default OurTeachersInfoSwiper