import styled from "@emotion/styled";
import { Stack } from "@mui/material";

export const HeaderWrapper = styled.header`
    background-color: ${({ theme }) => theme.colors.primary};
    position: sticky;
    top: 0;
    z-index: 120;
`;

export const StyledStack = styled(Stack) <{ matches: string }>`
    flex-direction: ${props => (props.matches === 'true' ? "row-reverse" : "row")};
    // justify-content: space-between;
    justify-content: flex-end;
    align-items: center;
    height: ${props => (props.matches === 'true' ? "80px" : "54px")};
    position: relative;
`;
