import FaqAccordion from './FaqAccordion'
import { Container, DescriptionComponent, TitleComponent } from '../../global_styles/styles'
import { TitleWrapper } from './styles'

const FAQ: React.FC = () => {
    return (
        <Container id='faq'>
            <TitleWrapper>
                <TitleComponent>FAQ</TitleComponent>
                <DescriptionComponent>
                    Discover what sets us apart – our key strengths that drive our success
                </DescriptionComponent>
            </TitleWrapper>
            <FaqAccordion />
        </Container>
    )
}

export default FAQ