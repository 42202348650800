import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { breakpoint_md } from "../../mixins";

const spin = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

type CustomButtonWrapperProps = {
  width?: string
  fillColor?: string
  backgroundColor?: string
  color?: string
}

export const CustomButtonWrapper = styled.div<CustomButtonWrapperProps>`
    border-radius: 10px;
    background: ${props => props.backgroundColor || "#AA233B"};
    padding: 8px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    &.white{
        background: ${props => props.backgroundColor || "#FFF"};
    }
    p {
        color: ${props => props.color || "#FFF"};
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: -0.6px;
    }
    svg {
        animation: ${spin} 2s linear infinite;
        fill: ${props => props.fillColor || "#fff"};
    }
    @media (min-width: ${breakpoint_md}) {
      padding: 12px 50px;
    }
        
`;

export const LogoWrapper = styled(Box)`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 110;
  cursor: pointer;
`;