import { HeaderWrapper, StyledStack } from "./styles";
import { Container } from "../../global_styles/styles";
import LogoComponent from "../../helper/components/LogoComponent";
import NavbarList from "../NavbarList";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakpoint_xl } from "../../mixins";

const Header: React.FC = () => {
  const matches = useMediaQuery(`(min-width: ${breakpoint_xl})`);

  return (
    <HeaderWrapper>
        <Container>
          <StyledStack matches={`${matches}`}>
                {/* <HelperLanguage/> */}
                <LogoComponent full={matches}/>
                <NavbarList full={matches}/>
            </StyledStack>
        </Container>
    </HeaderWrapper>
  );
};

export default Header;
