import { useState } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import { MarqueeContainer, MarqueePointer, MarqueeText} from "./styles";

type Message = string;

const messages: Message[] = [
    "FIRST ever created digital SAT platform in Uzbekistan!",
    "Register or Login right now!",
    "Nearly 100% percent similarity with real exam platform",
    "All tests are FREE for SATASHKENT members",
];

const PersonalizedBottom: React.FC = () => {

    const navigate = useNavigate();
    const [hidden, setHidden] = useState<boolean>(true);

    return (
        <MarqueeContainer
            onMouseEnter={() => setHidden(false)}
            onMouseLeave={() => setHidden(true)}
        >
            {hidden ? (
                <Marquee>
                    {messages.map((elem, index) => (
                        <MarqueePointer key={index}>
                            <MarqueeText>{elem}</MarqueeText>
                        </MarqueePointer>
                    ))}
                </Marquee> 
            ) : ( 
                <MarqueePointer onClick={() => navigate("/enrole")} >
                    <MarqueeText>Register or login!</MarqueeText>
                </MarqueePointer>
            )}
        </MarqueeContainer>
    );
}


export default PersonalizedBottom