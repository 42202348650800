import {
  ContactNumber,
  FooterLogoImg,
  FooterNavList,
  FooterNavListItem,
  FooterStack,
  FooterTitle,
  FooterWrapper,
  FullWidth,
  LogoWrapper,
  SocialMediaStack,
} from "./styles";
import { Box } from "@mui/material";
import { Container } from "../../global_styles/styles";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const navItems = [
    { label: "Results", id: "results" },
    { label: "Why us", id: "why-us" },
    { label: "Courses", id: "courses" },
    { label: "Platform", id: "personal-platform" },
    { label: "Teachers", id: "teachers" },
    { label: "FAQ", id: "faq" },
  ];

  const handleNavItemClick = (section: string) => {
    navigate("/");
    setTimeout(() => {
      const scrollElement = document.getElementById(section);
      if (scrollElement) {
        scrollElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, 100);
  };

  return (
    <FooterWrapper>
      <Container>
        <FullWidth>
          <FooterStack>
            <LogoWrapper>
              <FooterLogoImg
                src={"/images/footer/footer_logo.png"}
                alt="footer-logo"
              />
            </LogoWrapper>
            <Box>
              <FooterTitle gutterBottom>SATASHKENT</FooterTitle>
              <FooterNavList>
                {navItems.map((item) => (
                  <FooterNavListItem key={item.id}>
                    <ScrollLink
                      onClick={() => handleNavItemClick(item.id)}
                      smooth
                      duration={800}
                      to={item.id}
                    >
                      {item.label}
                    </ScrollLink>
                  </FooterNavListItem>
                ))}
              </FooterNavList>
            </Box>
            <Box>
              <Box>
                <FooterTitle gutterBottom>Our social media</FooterTitle>
                <SocialMediaStack>
                  <Box>
                    <a href="https://www.youtube.com/@satashkent">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M21.25 5H8.75C5 5 2.5 7.5 2.5 11.25V18.75C2.5 22.5 5 25 8.75 25H21.25C25 25 27.5 22.5 27.5 18.75V11.25C27.5 7.5 25 5 21.25 5ZM17.3625 16.2875L14.275 18.1375C13.025 18.8875 12 18.3125 12 16.85V13.1375C12 11.675 13.025 11.1 14.275 11.85L17.3625 13.7C18.55 14.425 18.55 15.575 17.3625 16.2875Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </a>
                  </Box>
                  <Box>
                    <a href="https://t.me/satashkent">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 25 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5 0.5C19.4038 0.5 25 6.0962 25 13C25 19.9038 19.4038 25.5 12.5 25.5C5.5962 25.5 0 19.9038 0 13C0 6.0962 5.5962 0.5 12.5 0.5ZM16.812 18.0951C17.0418 17.3897 18.119 10.3592 18.2522 8.97391C18.2924 8.55435 18.1598 8.27554 17.9 8.15109C17.5859 8 17.1207 8.07554 16.581 8.27011C15.8408 8.53696 6.37772 12.5549 5.83098 12.7875C5.3125 13.0076 4.82228 13.2478 4.82228 13.5957C4.82228 13.8402 4.96739 13.9777 5.36739 14.1207C5.7837 14.269 6.83207 14.587 7.45109 14.7576C8.04728 14.9223 8.72609 14.7793 9.10652 14.5429C9.50978 14.2924 14.1636 11.1783 14.4978 10.9054C14.8315 10.6326 15.0978 10.9821 14.825 11.2554C14.5522 11.5283 11.3576 14.6288 10.9364 15.0582C10.425 15.5793 10.788 16.1196 11.131 16.3359C11.5228 16.5826 14.3408 18.4728 14.7652 18.7761C15.1897 19.0793 15.6201 19.2168 16.0141 19.2168C16.4082 19.2168 16.6158 18.6978 16.812 18.0951Z"
                          fill="white"
                          fillOpacity="0.5"
                        />
                      </svg>
                    </a>
                  </Box>
                  <Box>
                    <a href="https://www.instagram.com/satashkent">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M20.2375 2.5H9.7625C5.2125 2.5 2.5 5.2125 2.5 9.7625V20.225C2.5 24.7875 5.2125 27.5 9.7625 27.5H20.225C24.775 27.5 27.4875 24.7875 27.4875 20.2375V9.7625C27.5 5.2125 24.7875 2.5 20.2375 2.5ZM15 19.85C12.325 19.85 10.15 17.675 10.15 15C10.15 12.325 12.325 10.15 15 10.15C17.675 10.15 19.85 12.325 19.85 15C19.85 17.675 17.675 19.85 15 19.85ZM22.4 8.6C22.3375 8.75 22.25 8.8875 22.1375 9.0125C22.0125 9.125 21.875 9.2125 21.725 9.275C21.575 9.3375 21.4125 9.375 21.25 9.375C20.9125 9.375 20.6 9.25 20.3625 9.0125C20.25 8.8875 20.1625 8.75 20.1 8.6C20.0375 8.45 20 8.2875 20 8.125C20 7.9625 20.0375 7.8 20.1 7.65C20.1625 7.4875 20.25 7.3625 20.3625 7.2375C20.65 6.95 21.0875 6.8125 21.4875 6.9C21.575 6.9125 21.65 6.9375 21.725 6.975C21.8 7 21.875 7.0375 21.95 7.0875C22.0125 7.125 22.075 7.1875 22.1375 7.2375C22.25 7.3625 22.3375 7.4875 22.4 7.65C22.4625 7.8 22.5 7.9625 22.5 8.125C22.5 8.2875 22.4625 8.45 22.4 8.6Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </a>
                  </Box>
                  <Box>
                    <a href="https://www.linkedin.com/company/satashkent/">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.0238 0.5H2.97619C1.33333 0.5 0 1.83333 0 3.47619V22.5238C0 24.1667 1.33333 25.5 2.97619 25.5H22.0238C23.6667 25.5 25 24.1667 25 22.5238V3.47619C25 1.83333 23.6667 0.5 22.0238 0.5ZM7.7381 10.0238V21.3333H4.16667V10.0238H7.7381ZM4.16667 6.73214C4.16667 5.89881 4.88095 5.2619 5.95238 5.2619C7.02381 5.2619 7.69643 5.89881 7.7381 6.73214C7.7381 7.56548 7.07143 8.2381 5.95238 8.2381C4.88095 8.2381 4.16667 7.56548 4.16667 6.73214ZM20.8333 21.3333H17.2619C17.2619 21.3333 17.2619 15.8214 17.2619 15.381C17.2619 14.1905 16.6667 13 15.1786 12.9762H15.131C13.6905 12.9762 13.0952 14.2024 13.0952 15.381C13.0952 15.9226 13.0952 21.3333 13.0952 21.3333H9.52381V10.0238H13.0952V11.5476C13.0952 11.5476 14.244 10.0238 16.5536 10.0238C18.9167 10.0238 20.8333 11.6488 20.8333 14.9405V21.3333Z"
                          fill="white"
                          fillOpacity="0.5"
                        />
                      </svg>
                    </a>
                  </Box>
                </SocialMediaStack>
              </Box>
              <Box>
                <FooterTitle gutterBottom>Our contacts</FooterTitle>
                <ContactNumber href={`tel:+998917876575`}>
                  +998 91 787 65 75
                </ContactNumber>
              </Box>
            </Box>
          </FooterStack>
        </FullWidth>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
