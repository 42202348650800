import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button, IconButton } from "@mui/material";
import { Link } from "react-scroll";

type NavbarListWrapperType = {
  open: string;
  full: string;
};

const ModileStyleNavbarListWrapper = css`
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: calc(100% - 54px);
  top: 54px;
  left: 0;
  z-index: 100;
  padding-top: 42px;
  padding-bottom: 25px;
`;

const DesktopStyleNavbarListWrapper = css`
  transform: none !important;
  display: block !important;
`;

export const NavbarListWrapper = styled.div<NavbarListWrapperType>`
  display: flex;
  background-color: ${(props) => props.theme.colors.primary};
  transform: ${(props) =>
    props.open === 'true' ? "translateX(0)" : "translateX(-100vw)"};
  ${(props) =>
    props.full === 'true' ? DesktopStyleNavbarListWrapper : ModileStyleNavbarListWrapper}
`;

type NavbarType = {
  full: string;
};

const ModileNavbarListUl = css`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 42px;
`;

const DesktopNavbarListUl = css`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

export const NavbarListUl = styled.ul<NavbarType>`
  width: 100%;
  display: flex;
  ${(props) => (props.full === 'true' ? DesktopNavbarListUl : ModileNavbarListUl)}
`;

export const MobileNavbarList = css`
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
`;
export const DesktopNavbarList = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.32px;
`;

export const NavbarListLi = styled.div<NavbarType>`
  color: white;
  cursor: pointer;
  text-align: center;
  ${(props) => (props.full === 'true' ? DesktopNavbarList : MobileNavbarList)}
`;

export const ScrollLink = styled(Link)<NavbarType>`
  padding: 32px 8px;
  &:hover {
    background-color: ${(props) => props.full === 'true' && "white"};
    color: ${(props) => props.full === 'true' && "black"};
  }
`;

export const EnroleButton = styled(Button)`
  width: 326px;
  border-radius: 10px;
  padding-top: 21px;
  padding-bottom: 21px;
  font-size: 20px;
  font-weight: 700;
  text-transform: none;
  line-height: 19px;
  letter-spacing: -0.6px;
  background-color: #fff;
`;

export const HumbergerWrapper = styled(IconButton)`
  color: #fff;
  padding: 0;
`;
