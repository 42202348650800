import { Container, DescriptionComponent, TitleComponent } from '../../global_styles/styles'
import Marquee from 'react-fast-marquee'
import { PartnerBox, PartnerMarqueeBox, TitleWrapper } from './styles'
import useQueryCustom from '../../hooks/useQueryCustom';

type CollabsItem = {
    img: string;
    alt: string;
}

const Collabs: React.FC = () => {
    const collabs: CollabsItem[] | [] = useQueryCustom('/collabs.json', 'collabs');

    return (
        <>
            <Container>
                <TitleWrapper>
                    <TitleComponent>Our collabs</TitleComponent>
                    <DescriptionComponent>
                        Discover what sets us apart – our key strengths that drive our success
                    </DescriptionComponent>
                </TitleWrapper>
            </Container>
            <PartnerMarqueeBox>
                <Marquee speed={100} delay={0}>
                    {
                        collabs.length && collabs.map((collab: CollabsItem, index) => (
                            <PartnerBox key={index}>
                                <img loading="lazy" width="100px" height="80px" src={collab.img} alt={collab.alt} />
                            </PartnerBox>
                    ))}
                </Marquee>
            </PartnerMarqueeBox>
        </>
    )
}

export default Collabs