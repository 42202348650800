import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import { AccordionBodyText, AccordionHeaderText, BoxWithPadding } from './styles';
import { Box } from '@mui/material';
import useQueryCustom from '../../hooks/useQueryCustom';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<Box>
            <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" width="35" height="35" rx="10" fill="#AA233B" />
                <g clipPath="url(#clip0_2858_2189)">
                    <path d="M18.002 11.5449V23.4546" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.0469 17.499H23.9566" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_2858_2189">
                        <rect width="20.4167" height="20.4167" fill="white" transform="translate(7.79297 7.29102)" />
                    </clipPath>
                </defs>
            </svg>
        </Box>}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row',
    padding: 0,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "8px 0 16px 0"
}));

interface FAQItem {
    question: string;
    answer: string;
}

export default function FaqAccordion() {
    const data: FAQItem[] = useQueryCustom('/faq.json', 'faq');
    const [expanded, setExpanded] = React.useState<string>('faq-0');

    const handleChange = (index: string) => {
        setExpanded((prev) => (prev === index ? '' : index));
    };

    return (
        <BoxWithPadding>
            {data.map((item, index) => (
                <Box key={`faq-${index}`}>
                    <Accordion
                        expanded={expanded === `faq-${index}`}
                        onChange={() => handleChange(`faq-${index}`)}
                    >
                        <AccordionSummary aria-controls={`panel${index + 1}-content`} id={`panel${index + 1}-header`}>
                            <AccordionHeaderText>{item.question}</AccordionHeaderText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AccordionBodyText>{item.answer}</AccordionBodyText>
                        </AccordionDetails>
                    </Accordion>
                    {index !== data.length - 1 && (
                        <Divider color="#000" sx={{ opacity: 0.5 }} />
                    )}
                </Box>
            ))}
        </BoxWithPadding>
    );
}
