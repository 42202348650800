import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: image,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=22x`,
    };
}

const PlatformImages: React.FC = () => {
    return (
        <ImageList
            sx={{ maxWidth: "527px", height: "auto", overflow: "hidden", marginBottom: '10px' }}
            variant="quilted"
            cols={4}
            rowHeight={150}
        >
            {itemData.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img
                        {...srcset(item.img, 150, item.rows, item.cols)}
                        alt={item.title}
                        style={{ borderRadius: "20px", objectFit: "fill" }}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    )
}

export default PlatformImages;

const itemData: any[] = [];
