import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
const useQueryCustom = (path: string, querykey: string) => {
    async function fetchData() {
        const res = await axios.get(`/mock_data${path}`)
        return res.data[querykey];
    }
    const { data = [] } = useQuery({
        queryKey: [querykey],
        queryFn: fetchData
    })
    return data;
};
export default useQueryCustom;