import { Container, DescriptionComponent, TitleComponent } from '../../global_styles/styles'
import { Box } from '@mui/material'
import OurTeachersInfoSwiper from './OurTeachersInfoSwiper'
import { TitleWrapper } from './styles'

const OurTeachersInfo: React.FC = () => {
  return (
    <Box className="our-teachers-info">
        <Container id='teachers'>
            <TitleWrapper>
                <TitleComponent>Our Teachers</TitleComponent>
                <DescriptionComponent>
                    Discover what sets us apart – our key strengths that drive our success
                </DescriptionComponent>
            </TitleWrapper>
        </Container>
        <OurTeachersInfoSwiper/>
    </Box>
  )
}

export default OurTeachersInfo