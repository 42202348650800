import ServiceType from './ServiceType';

const WhyUs: React.FC = () => {

  return (
    <div
      id='why-us'
      className='pb-[60px]'
    >
      <ServiceType/>
    </div>
  )
};

export default WhyUs;
  
