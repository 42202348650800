import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { breakpoint_md } from "../../mixins";

export const MarqueeContainer = styled(Box)`
    border-top: 4px solid #AA233B;
    border-bottom: 4px solid #AA233B;
    margin-bottom: 60px;
    transition: background-color 0.3s, border-radius 0.3s;

    &:hover {
        background-color: #AA233B;
        border-radius: 10px;
    }
`;

export const MarqueePointer = styled(Box)`
    cursor: pointer;
`

export const MarqueeText = styled.div`
    padding: 10px 20px;
    color: #aa233b;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.6px;
    min-width: 100%;

    @media (min-width: ${breakpoint_md}) {
        padding: 40px 20px;
        text-transform: uppercase;
    }

    :hover {
        color: white;
    }
`;