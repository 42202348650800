import styled from "@emotion/styled";
import swipeable_bg1 from "../../media/swipeable_bg1.png";
import swipeable_bg2 from "../../media/swipeable_bg2.png";
import { Box, Typography } from "@mui/material";
import { breakpoint_lg, breakpoint_md, breakpoint_sm, breakpoint_xl, breakpoint_xxl } from "../../mixins";

export const TitleWrapper = styled(Box)`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 40px;
`;

export const SwiperWrapper = styled(Box)`
    width: 100%;
    flex-grow: 1;
    padding: 15px 0;
    background-color: #EBECED;
    border-radius: 20px;
    margin-bottom: 40px;
`;

export const ResultsItemWrapper = styled.div`
    padding: 0 15px;
    @media screen and (min-width: ${breakpoint_md}) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: stretch;
        background-image: url(${swipeable_bg1}) , url(${swipeable_bg2});
        background-position: 4px calc(100% - 87px), center bottom;
        background-repeat: no-repeat;
    }
`

export const ResultsItemImageScore = styled.div`
    width: 100%;
    @media screen and (min-width: ${breakpoint_md}) {
        width: calc(60% - 10px);
    }
    @media screen and (min-width: ${breakpoint_xl}) {
        width: 560px;
    }
`

export const ResultsItemImage = styled.img`
    height: auto;
    width: 100%;
    object-fit: fit;
    border-radius: 20px;
    border: 2px solid #000;
    margin: 0 auto;
`

export const ResultsItemScore = styled.div`
    padding-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    gap: 10px;
`

export const ResultsItemScoreBox = styled.div`
    min-width: 150px;
    width: calc(50% - 10px);
    border-radius: 5px;
    background: #AA233B;
    padding: 5px 6px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    height: 55px;
    @media screen and (min-width: ${breakpoint_md}) {
        border-radius: 10px;
        height: 80px;
    }
    @media screen and (min-width: ${breakpoint_lg}) {
        height: 110px;
    }
`

export const BoxSelfEnd = styled(Box)`
    align-self: flex-end;
`
export const BoxSelfStart = styled(Box)`
    align-self: flex-start;
` 

export const FlexColumnBox = styled(Box)`
    display: flex;
    flex-direction: column;
`
export const ScoreType = styled(Typography)`
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    @media (min-width: ${breakpoint_md}) {
        font-size: 14px;
    }
    @media (min-width: ${breakpoint_lg}) {
        font-size: 20px;
    }
`;

export const SatScore = styled(Typography)`
    color: #FFF;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (min-width: ${breakpoint_md}) {
        font-size: 48px;
    }
    @media (min-width:  ${breakpoint_lg}) {
        font-size: 56px;
    }
`;
export const SuperScoreBox = styled.div`
    min-width: 150px;
    width: calc(50% - 10px);
    border-radius: 5px;
    background-color: #DFDFDF;
    color: #CBCBCB;
    display: flex;
    justify-content: space-between;
    height: 55px;
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
    p {
        font-size: 1.2rem;
        font-weight: bold;
        margin: auto;
        @media screen and (min-width: ${breakpoint_md}) {
            font-size: 1.5rem;
        }
        @media screen and (min-width: ${breakpoint_xl}) {
            font-size: 1.875rem;
        }
    }
    @media screen and (min-width: ${breakpoint_md}) {
        border-radius: 10px;
        height: 80px;
    }
    @media screen and (min-width: ${breakpoint_lg}) {
        height: 110px;
    }
`;

export const ResultsItemInfo = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
    @media screen and (min-width: ${breakpoint_md}) {
        width: calc(40% - 10px);
        padding-top: 0;
    }
    @media screen and (min-width: ${breakpoint_xl}) {
        width: 390px;
    }
`
export const StudentFullName = styled(Typography)`
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media (min-width: ${breakpoint_xl}) {
        font-size: 48px;
    }
`;

export const StudentTestomonial = styled(Typography)`
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
    @media (min-width: ${breakpoint_xl}) {
        font-size: 14px;
    }
`;

export const ResultsItemInfoButtons = styled.div`
    display: flex;
    gap: 15px;
    padding: 5px 0;
`

export const ResultsItemInfoButton = styled.div`
    width: 50px;
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.5s ease;
    cursor: pointer;
    :hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`

export const OurResultsWrapper = styled.div`
    margin: 48px 0;
    font-size: small;
    @media (min-width: ${breakpoint_xl}) {
        margin: 58px 0;
        font-size: unset;
    }
`
export const Line = styled.div`
    width: 100%;
    height: 2px;
    background: #D7D8D9;
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`
export const GreyLeftDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #D7D8D9;
    position: absolute;
    left: 0;
    top: -5px;
    span {
        position: absolute;
        left: 12px;
        top: -14px;
        color: #D7D8D9;
    }
    @media (min-width: ${breakpoint_sm}) {
        width: 15px;
        height: 15px;
        top: -7px;
        span {
            left: 18px;
        }
    }
`
export const GreyRightDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #D7D8D9;
    position: absolute;
    right: -3px;
    top: -5px;
    span {
        position: absolute;
        right: 12px;
        top: -14px;
        color: #D7D8D9;
    }
    @media (min-width: ${breakpoint_sm}) {
        width: 15px;
        height: 15px;
        top: -7px;
        span {
            right: 18px;
        }
    }
`
export const OurScore = styled.div<{ score: number }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -32px;
    left: ${(props) => props.score}%;
    transform: translateX(-50%);
    z-index: 10;
    line-height: 1;
    span {
        color: #aa233b;
        font-weight: bold; 
        font-size: 20px;
    }
    svg {
        width: 24px;
        height: 24px;
    }
    @media (min-width: ${breakpoint_sm}) {
        svg {
            width: 26px;
            height: 26px;
        }
    }
    @media (min-width: ${breakpoint_md}) {
        top: -34px;
        svg {
            width: 30px;
            height: 30px;
        }
    }
    @media (min-width: ${breakpoint_xl}) {
        top: -44px;
        span {
            font-size: 26px;
        }
        svg {
            width: 36px;
            height: 36px;
        }
    }
    @media (min-width: ${breakpoint_xxl}) {
        top: -50px;
        span {
            font-size: 32px;
        }
        svg {
            width: 40px;
            height: 40px;
        }
    }
`
export const WorldsAverageScore = styled.div<{ score: number }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -25px;
    left: ${(props) => props.score}%;
    z-index: 10;
    .number {
        line-height: 1;
        font-size: 16px;
        color: #B7B7B8;
    }
    .text {
        font-size: 12px;
        color: #B7B7B8;
    }
    @media (min-width: ${breakpoint_sm}) {
        svg {
            width: 20px;
            height: 20px;
        }
    }
    @media (min-width: ${breakpoint_md}) {
        top: -26px;
        svg {
            width: 22px;
            height: 22px;
        }
        .number {
            font-size: 18px;
        }
        .text {
            font-size: 14px;
        }
    }
    @media (min-width: ${breakpoint_xl}) {
        top: -36px;
        .number {
            font-size: 20px;
        }
        .text {
            font-size: 16px;
        }
        svg {
            width: 30px;
            height: 30px;
        }
    }
    @media (min-width: ${breakpoint_xxl}) {
        top: -42px;
        .number {
            font-size: 28px;
        }
        .text {
            font-size: 20px;
        }
        svg {
            width: 30px;
            height: 30px;
        }
    }
`
export const Redline = styled.div<{ position: number , width: number}>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #aa233b;
    height: 2px;
    left: ${(props) =>props.position}%;
    width: ${(props) => props.width}%;
    span {
        position: absolute;
        top: 5px;
        color: #aa233b;
        font-size: 14px;
        font-weight: bold;
    }
    @media (min-width: ${breakpoint_sm}) {
        span {
           font-size: 16px; 
        }
    }
    @media (min-width: ${breakpoint_md}) {
        span {
           font-size: 18px; 
        }
    }
    @media (min-width: ${breakpoint_xl}) {
        span {
           top: 8px;
           font-size: 22px; 
        }
    }
    @media (min-width: ${breakpoint_xxl}) {
        span {
           font-size: 24px; 
        }
    }
`
