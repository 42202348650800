import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomNavigation,
  FullTuition,
  FullTuitionText,
  SpinningIconWrapper,
  StudentBox,
  StudentBoxImage,
  StudentBoxName,
  StudentImage,
  StudentImgInfo,
  StudentList,
  StudentResultsWrapper,
  StudentTestimonial,
  UniversityBox,
  UniversityBoxInfo,
  UniversityBoxInfoIcon,
  UniversityBoxInfoText,
  UniversityLogo,
} from "./styless";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import useQueryCustom from "../../hooks/useQueryCustom";
import { ExpandableText } from "../../helper/components";
import { SpinningIcon } from "../../global_styles/styles";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

type StudentsType = {
  id: string;
  name: string;
  img: string;
  ielts_score: number;
  info: string;
  scholarship: string;
  education: {
    logo: string;
    name: string;
  };
  isActive: boolean;
};

const StudentResults: React.FC = () => {
  const students: StudentsType[] | [] = useQueryCustom(
    "/students.json",
    "students",
  );
  const [allStudents, setAllStudents] = useState<StudentsType[]>(students);
  const [selectedStudent, setSelectedStudent] = useState<StudentsType>(
    students[0],
  );

  useEffect(() => {
    setSelectedStudent(students[0]);
    setAllStudents(students);
  }, [students]);

    const handleSelectedStudent = (student: StudentsType) => {
        setSelectedStudent(student);
        setAllStudents(allStudents.map((item: StudentsType) => ({
            ...item,
            isActive: item.id === student.id,
        })))
    }
    return (
        <StudentResultsWrapper>
            <StudentList>
                <CustomNavigation>
                    <Swiper
                        loop={true}
                        slidesPerView={1.5}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}
                        navigation
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        breakpoints={{
                            480: {
                                slidesPerView: 2.5,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 6,
                                spaceBetween: 20,
                                direction: "vertical"
                            },
                        }}
                    >
                        {allStudents.length && allStudents.map((item: StudentsType) => (
                            <SwiperSlide key={item.id}
                            >
                                <StudentBox isactive={`${item.isActive}`}
                                    onClick={() => handleSelectedStudent(item)}>
                                    <StudentBoxImage src={item.img} alt="Image description" loading="lazy" />
                                    <StudentBoxName>
                                        <p>{item.name}</p>
                                        <span>{item.ielts_score}</span>
                                    </StudentBoxName>
                                </StudentBox>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </CustomNavigation>
            </StudentList>

            <StudentImgInfo>
                <StudentImage>
                    <img src={selectedStudent?.img} alt="student" loading="lazy" />
                </StudentImage>
                <Box>
                    <StudentTestimonial>
                        <ExpandableText text={selectedStudent?.info} />
                    </StudentTestimonial>
                    <UniversityBox>
                        <UniversityLogo src={selectedStudent?.education?.logo} alt="University" loading="lazy" />
                        <UniversityBoxInfo>
                            <UniversityBoxInfoText>
                                <span>Accepted</span>
                                <p>{selectedStudent?.education?.name}</p>
                            </UniversityBoxInfoText>
                            <UniversityBoxInfoIcon>
                                <SpinningIconWrapper>
                                    <SpinningIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                            <path d="M26.7024 5.24609L21.3137 0L12.5737 8.50858L11.2466 3.60179L3.8874 5.49403L7.05094 17.1607L1.99732 15.8296L0 22.981L11.9973 26.1521L8.28418 29.7409L13.6595 35L22.4531 26.4914L23.7936 31.3852L31.1528 29.4799L27.9625 17.8263L33.0161 19.1443L35 11.9799L23.0295 8.84787L26.7292 5.24609H26.7024ZM20.4156 15.8557L26.7426 17.5131L20.4156 19.1573L22.1046 25.3169L17.4933 20.8016L12.8418 25.3039L14.5576 19.1443L8.25738 17.4739L14.5576 15.8557L12.8954 9.73527L17.4799 14.1984L22.1046 9.69612L20.4156 15.8296V15.8557Z" fill="black" />
                                        </svg>
                                    </SpinningIcon>
                                </SpinningIconWrapper>
                            </UniversityBoxInfoIcon>
                        </UniversityBoxInfo>
                    </UniversityBox>

          <FullTuition>
            <FullTuitionText>
              <span>Scholarship</span>
              <p>{selectedStudent?.scholarship}</p>
            </FullTuitionText>
            <SpinningIconWrapper>
              <SpinningIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                >
                  <path
                    d="M26.7024 5.24609L21.3137 0L12.5737 8.50858L11.2466 3.60179L3.8874 5.49403L7.05094 17.1607L1.99732 15.8296L0 22.981L11.9973 26.1521L8.28418 29.7409L13.6595 35L22.4531 26.4914L23.7936 31.3852L31.1528 29.4799L27.9625 17.8263L33.0161 19.1443L35 11.9799L23.0295 8.84787L26.7292 5.24609H26.7024ZM20.4156 15.8557L26.7426 17.5131L20.4156 19.1573L22.1046 25.3169L17.4933 20.8016L12.8418 25.3039L14.5576 19.1443L8.25738 17.4739L14.5576 15.8557L12.8954 9.73527L17.4799 14.1984L22.1046 9.69612L20.4156 15.8296V15.8557Z"
                    fill="white"
                  />
                </svg>
              </SpinningIcon>
            </SpinningIconWrapper>
          </FullTuition>
        </Box>
      </StudentImgInfo>
    </StudentResultsWrapper>
  );
};

export default StudentResults;
