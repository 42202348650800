import { Container, DescriptionComponent, TitleComponent } from '../../global_styles/styles'
import StudentResults from './StudentResults'
import { TitleWrapper } from './styless'

const Students: React.FC = () => {
    return (
        <Container className='min-h-[100vh]'>
            <TitleWrapper>
                <TitleComponent>Our students everywhere</TitleComponent>
                <DescriptionComponent>
                    Discover what sets us apart – our key strengths that drive our success
                </DescriptionComponent>
            </TitleWrapper>
            <StudentResults />
        </Container>
    )
}

export default Students