import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { breakpoint_md } from "../../mixins";
import { Link } from "react-router-dom";

export const ItemWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 54px);
    background: linear-gradient(0deg, #540412 15.48%, #7c2032 76.25%);
    @media screen and (min-width: 1200px) {
        height: calc(100vh - 80px);
    }

`

export const ItemContainer = styled.div`
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

export const ItemInfo = styled.div`
    height: calc(100vh - 200px);
    @media screen and (min-width: 700px) {
        display: flex;
    }
    @media screen and (min-width: 1200px) {
        gap: 100px;
    }
`

export const ItemText = styled(Box)`
    @media screen and (min-width: 700px) {
        width: 50%;
        padding: 0 50px;
        display: flex;
        max-height: calc(100vh - 140px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @media screen and (min-width: 1200px) {
        width: 500px;
        padding: 0;
    }
`

export const ItemModileButton = styled.div`
    display: inline-block;
    @media screen and (min-width: 700px) {
        display: none;
    }
`

export const ItemImageBox = styled.div`
    width: 100%;
    max-height: calc(100vh - 400px);
    overflow: auto;
    @media screen and (min-width: 700px) {
        width: 50%;
        max-height: calc(100vh - 140px);
    }
    @media screen and (min-width: 1200px) {
        width: 500px;
    }
`

//////////
export const ServiceTypeWrapper = styled(Box)`
    background-color: #000;
    position: relative;
    overflow: hidden;
    margin-bottom: -1px;
    z-index: 1;
    padding: 0 10px;
`

export const WhyUsText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .container {
        /* height: 100%; */
        padding: 40px;
        background-color: #000;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        h4 {
            color: #FFF;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 16px;
        }
        .whyustextp {
            color: rgba(255, 255, 255, 0.70);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 30px;
            max-width: 450px;
        }
    }
`

export const LinkToEnrole = styled(Link)`
    width: 320px;
    cursor: pointer;
`

export const ServiceTypeLeft = styled(Box)`
    display: none;
    @media screen and (min-width: ${breakpoint_md}) {
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

export const ServiceTypeLeftContainer = styled(Box)`
    width: 100%;
    height: 50%;
    position: relative;
    .photo {
        position: absolute;
        width: 100%;
        height: 100%;
    }
`

export const ServiceTypeRight = styled(Box)`
    width: 100%;
    padding: 30px 0;
    @media screen and (min-width: ${breakpoint_md}) {
        width: 50%;
        margin-left: auto;
    }
`

export const ServiceTypeRightDetail = styled(Box)`
    /* height: 100vh; */
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    .photo {
        position: absolute;
        width: 100%;
        height: 100%;
    }
`