import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ResultsItem from './ResultsItem';
import useQueryCustom from '../../hooks/useQueryCustom';
import { GreyLeftDot, GreyRightDot, Line, OurResultsWrapper, OurScore, Redline, SwiperWrapper, WorldsAverageScore } from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface ResultsType {
    id: string;
    name: string;
    info: string;
    certification: string;
    total_score: string | number;
    super_score: string | number
}

function SwipeableTextMobileStepper() {
    const results: ResultsType[] | [] = useQueryCustom('/results.json', 'results');

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = results.length;
    const scores = results.map(entry => Number(entry.total_score));

    const minScore = Math.min(...scores);
    // const maxScore = Math.max(...scores); // TODO: It should be enabled again
    const maxScore = 1580;

    const handleNext = () => {
        if (activeStep < maxSteps - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const calculatePercentage = (value: number) => {
        return Math.ceil(((value - 400) / 1200) * 100);
    };

    return (
        <div>
            <SwiperWrapper>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={null}
                    backButton={null}
                    sx={{
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        backgroundColor: "transparent",
                        "& .MuiMobileStepper-dots": {
                            width: "100%",
                            "& .MuiMobileStepper-dot": {
                                width: "100%",
                                borderRadius: "4px",
                                height: "4px"
                            }
                        }
                    }}
                />
                <AutoPlaySwipeableViews
                    axis={'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {results.map((item: ResultsType, index) => (
                        <div key={index}>
                            {Math.abs(activeStep - index) <= 2 && (
                                <ResultsItem item={item} handleNext={handleNext} handleBack={handleBack} />
                            )}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
            </SwiperWrapper>
            <OurResultsWrapper>
                <Line>
                    <GreyLeftDot>
                        <span>400</span>
                    </GreyLeftDot>
                    <GreyRightDot>
                        <span>1600</span>
                    </GreyRightDot>
                    <WorldsAverageScore score={calculatePercentage(500)}>
                        <span className="number">1100</span>
                        <svg width="20" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="7.5" cy="8.11178" rx="7.5" ry="7.49606" fill="#B7B7B8"/>
                            <ellipse cx="7.5" cy="8.11167" rx="4" ry="3.9979" fill="white"/>
                        </svg>
                        <span className="text">World's average</span>
                    </WorldsAverageScore>
                    <OurScore score={50}>
                        <span>{minScore}</span>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="12.1992" cy="12.8116" rx="12" ry="11.9937" fill="#AA233B"/>
                            <ellipse cx="12.1992" cy="12.8117" rx="9" ry="8.99527" fill="white"/>
                        </svg>
                    </OurScore>
                    <Redline width={35} position={50}>
                        <span>Our results</span>
                    </Redline>
                    <OurScore score={85}>
                        <span>{maxScore}</span>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="12.1992" cy="12.8116" rx="12" ry="11.9937" fill="#AA233B"/>
                            <ellipse cx="12.1992" cy="12.8117" rx="9" ry="8.99527" fill="white"/>
                        </svg>
                    </OurScore>
                </Line>
            </OurResultsWrapper>
        </div>
    );
}

export default SwipeableTextMobileStepper;
