import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const TitleWrapper = styled(Box)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 40px;
`;

export const PartnerImgeBox = styled.div`
    width: 100px;
    height: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        display: inline-block;
        width: 100%;
        max-width: 250px;
    }
`

export const PartnerBox = styled.div`
    padding: 0 30px;
    img {
        width: 100%;
        height: 80px;
        cursor: pointer;
    }
`

export const PartnerMarqueeBox = styled.div`
    padding-bottom: 60px;
    position: relative;
`