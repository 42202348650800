import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { breakpoint_md, breakpoint_sm, breakpoint_xxl } from "../../mixins";

type TeacherBoxProps = {
    image: string
}

export const TitleWrapper = styled(Box)`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 20px;
`;

export const TeacherBox = styled.div<TeacherBoxProps>`
    width: 80%;
    height: 480px;
    flex-shrink: 0;
    border-radius: 20px;
    background: url(${props => props.image}) lightgray 50% / cover no-repeat;
    margin-bottom: 52px;
    margin: 0 auto;
    position: relative;
    @media screen and (min-width: ${breakpoint_sm}) {
        width: 90%;
    }
    @media screen and (min-width: ${breakpoint_md}) {
        width: 100%;
        height: 480;
    }
    :hover {
        .teacher-box-info {
            display: block;
        }
    }
`

export const TeacherBoxInfo = styled.div`
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 14px;
    display: none;
`

export const TeacherBoxInfoContainer = styled.div`
    border-radius: 12px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(2.4000000953674316px);
    p {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    @media screen and (min-width: ${breakpoint_xxl}) {
        p {
            font-size: 16px;
        }
    }
`

export const TeacherBoxInfoHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
`

export const TeacherBoxInfoHeaderText = styled.div`
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const TeacherBoxInfoHeaderScore = styled.div`
    p {
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    span {
        color: #FFF;
        font-size: 28.8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`

export const PaddingBottom = styled(Box)`
    padding-bottom: 60px;
`   