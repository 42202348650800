import { BoxSelfEnd, BoxSelfStart, FlexColumnBox, ResultsItemImage, ResultsItemImageScore, ResultsItemInfo, ResultsItemInfoButton,
    ResultsItemInfoButtons, ResultsItemScore, ResultsItemScoreBox, ResultsItemWrapper, SatScore,  ScoreType, StudentFullName, StudentTestomonial, SuperScoreBox
} from './styles'
import { ResultsType } from './Swipeable'
import { SpinningIcon } from '../../global_styles/styles'

type ResultsItemProps = {
    handleNext: () => void,
    handleBack: () => void,
    item: ResultsType
}

const ResultsItem: React.FC<ResultsItemProps> = ({item, handleNext, handleBack }) => {
    return (
        <ResultsItemWrapper>
            <ResultsItemImageScore>
                <ResultsItemImage src={item.certification} alt='score sertificate image' loading="lazy" />
                <ResultsItemScore>
                    <ResultsItemScoreBox>
                        <BoxSelfEnd>
                            <FlexColumnBox>
                                <ScoreType>
                                    Total score
                                </ScoreType>
                                <SatScore>
                                    {item.total_score}
                                </SatScore>
                            </FlexColumnBox>
                        </BoxSelfEnd>
                        <BoxSelfStart>
                            <SpinningIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                    <path d="M22.8878 4.49958L18.2689 0.00292969L10.7775 7.296L9.63998 3.09018L3.33206 4.7121L6.04366 14.7121L1.71199 13.5712L0 19.7009L10.2834 22.419L7.10073 25.4951L11.7082 30.0029L19.2455 22.7099L20.3945 26.9045L26.7024 25.2714L23.9678 15.2826L28.2995 16.4123L30 10.2714L19.7396 7.58682L22.9108 4.49958H22.8878ZM17.499 13.5935L22.9223 15.0141L17.499 16.4235L18.9468 21.7032L14.9943 17.8329L11.0073 21.692L12.478 16.4123L7.07775 14.9806L12.478 13.5935L11.0532 8.34745L14.9828 12.173L18.9468 8.31389L17.499 13.5712V13.5935Z" fill="white" />
                                </svg>
                            </SpinningIcon>
                        </BoxSelfStart>
                    </ResultsItemScoreBox>
                    {item.super_score ? <ResultsItemScoreBox>
                        <BoxSelfEnd>
                            <FlexColumnBox>
                                <ScoreType> Super score </ScoreType>
                                <SatScore> {item.super_score} </SatScore>
                            </FlexColumnBox>
                        </BoxSelfEnd>
                        <BoxSelfStart>
                            <SpinningIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                    <path d="M22.8878 4.49958L18.2689 0.00292969L10.7775 7.296L9.63998 3.09018L3.33206 4.7121L6.04366 14.7121L1.71199 13.5712L0 19.7009L10.2834 22.419L7.10073 25.4951L11.7082 30.0029L19.2455 22.7099L20.3945 26.9045L26.7024 25.2714L23.9678 15.2826L28.2995 16.4123L30 10.2714L19.7396 7.58682L22.9108 4.49958H22.8878ZM17.499 13.5935L22.9223 15.0141L17.499 16.4235L18.9468 21.7032L14.9943 17.8329L11.0073 21.692L12.478 16.4123L7.07775 14.9806L12.478 13.5935L11.0532 8.34745L14.9828 12.173L18.9468 8.31389L17.499 13.5712V13.5935Z" fill="white" />
                                </svg>
                            </SpinningIcon>
                        </BoxSelfStart>
                    </ResultsItemScoreBox> : 
                    <SuperScoreBox>
                        <p>No superscore</p>
                    </SuperScoreBox>
                    }
                </ResultsItemScore>
            </ResultsItemImageScore>
            <ResultsItemInfo>
                <div>
                    <StudentFullName>
                        {item.name}
                    </StudentFullName>
                    <StudentTestomonial>
                        {item.info}
                    </StudentTestomonial>
                </div>
                <ResultsItemInfoButtons>
                    <ResultsItemInfoButton
                        onClick={handleBack}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M0.190592 8.03209L4.23059 14.019C4.57059 14.5277 5.12059 14.835 5.71059 14.835C7.20059 14.835 8.06059 13.0548 7.20059 11.7726L4.27059 7.4175L7.20059 3.06238C8.07059 1.78022 7.20059 3.05176e-05 5.71059 3.05176e-05C5.12059 3.05176e-05 4.56059 0.307325 4.22059 0.815952L0.180592 6.80291C-0.0594077 7.17378 -0.0594077 7.66121 0.190592 8.03209Z" fill="black" />
                            <path d="M7.19059 8.03209L11.2206 14.019C11.5706 14.5277 12.1206 14.835 12.7106 14.835C14.2006 14.835 15.0706 13.0548 14.2006 11.7726L11.2706 7.4175L14.2006 3.06238C15.0706 1.78022 14.2006 3.05176e-05 12.7106 3.05176e-05C12.1206 3.05176e-05 11.5606 0.307325 11.2206 0.815952L7.18059 6.80291C6.94059 7.17378 6.94059 7.66121 7.19059 8.03209Z" fill="black" />
                        </svg>
                    </ResultsItemInfoButton>
                    <ResultsItemInfoButton
                        onClick={handleNext}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M14.3563 6.80288L10.3163 0.815921C9.97628 0.307295 9.42628 0 8.83628 0C7.34628 0 6.48628 1.78019 7.34628 3.06235L10.2763 7.41747L7.34628 11.7726C6.47628 13.0547 7.34628 14.8349 8.83628 14.8349C9.42628 14.8349 9.98628 14.5276 10.3263 14.019L14.3663 8.03205C14.6063 7.66118 14.6063 7.17375 14.3563 6.80288Z" fill="black" />
                            <path d="M7.35628 6.80288L3.32628 0.815921C2.97628 0.307295 2.42628 0 1.83628 0C0.346283 0 -0.523717 1.78019 0.346283 3.06235L3.27628 7.41747L0.346283 11.7726C-0.523717 13.0547 0.346283 14.8349 1.83628 14.8349C2.42628 14.8349 2.98628 14.5276 3.32628 14.019L7.36628 8.03205C7.60628 7.66118 7.60628 7.17375 7.35628 6.80288Z" fill="black" />
                        </svg>
                    </ResultsItemInfoButton>
                </ResultsItemInfoButtons>
            </ResultsItemInfo>
        </ResultsItemWrapper>
    )
}

export default ResultsItem