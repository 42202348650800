import React from 'react'
import { NotFoundBox, NotFoundContainer, NotFoundImg, NotFoundWrapper } from './styles'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    
    return (
        <NotFoundWrapper>
            <NotFoundContainer>
                <NotFoundBox>
                    <NotFoundImg src={'/images/error/number_404.png'} alt="Page not found" />
                    <Typography
                        color="#fff"
                        fontSize="64px"
                        fontStyle="normal"
                        fontWeight="700"
                        lineHeight="76.8px"
                        letterSpacing="-1.92px"
                    >
                        Oops!
                    </Typography>
                    <Typography
                        color="#fff"
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="27px"
                        letterSpacing="-0.4px"
                        paddingBottom="25px"
                        textAlign="center"
                        sx={{
                            opacity: "0.5"
                        }}
                    >
                        Seems like you got rejected from University:) Just kidding, page was removed
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            display: "inline-block",
                            borderRadius: "10px",
                            padding: "20px 80px",
                            fontSize: "20px",
                            fontWeight: "700",
                            textTransform: "none",
                            lineHeight: "19px",
                            letterSpacing: "-0.6px",
                        }}
                        onClick={() => navigate('/')}
                    >
                        Go home
                    </Button>
                </NotFoundBox>
            </NotFoundContainer>
        </NotFoundWrapper>
    )
}

export default NotFoundPage