import React, { useState } from 'react';

interface ExpandableTextProps {
  text: string;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const maxLength = 245; 
  const shouldTruncate = text?.length > maxLength;

  const truncatedText = shouldTruncate ? text.slice(0, maxLength)  : text;

  return (
    <div>
      <p>
        {isExpanded || !shouldTruncate ? text : truncatedText}
        {' '}
        {shouldTruncate && (
            <button
            onClick={toggleExpanded}
            className="text-gray-500 hover:text-blue-600 hover:underline "
            >
            {isExpanded ? ' hide' : ' more...'}
            </button>
        )}
      </p>
    </div>
  );
};

export default ExpandableText;
