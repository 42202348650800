import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const TitleWrapper = styled(Box)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`;

export const BoxWithPadding = styled(Box)`
  padding: 36px 10px;
`;

export const AccordionHeaderText = styled(Typography)`
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
`

export const AccordionBodyText = styled(Typography)`
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.9px;
    letter-spacing: -0.28px;
`