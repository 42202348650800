import { Container, DescriptionComponent, TitleComponent } from '../../global_styles/styles'
import Swipeable from './Swipeable'
import { TitleWrapper } from './styles'

const Impressive: React.FC = () => {
  return (
    <Container id='results'>
        <TitleWrapper>
            <TitleComponent>Impressive results</TitleComponent>
            <DescriptionComponent>
                Discover what sets us apart – our key strengths that drive our success
            </DescriptionComponent>
        </TitleWrapper>
        <Swipeable/>
    </Container>
  )
}

export default Impressive