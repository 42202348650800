import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import { breakpoint_md } from "../../mixins";

export const FullWidth = styled(Box)`
    width: 100%;
`
export const LogoWrapper = styled(Box)`
    padding-bottom: 45px;
    cursor: pointer;
`;

export const FooterTitle = styled(Typography)`
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.72px;
`;

export const FooterStack = styled(Stack)`
    padding: 32px 0 60px 0;

    @media (min-width: ${breakpoint_md}) {
        padding: 82px 0 82px 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
`;

export const SocialMediaStack = styled(Stack)`
    flex-direction: row;
    gap: 14px;
    padding-top: 16px;
    padding-bottom: 20px;
`;

export const ContactNumber = styled.a`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.3px;
    letter-spacing: -0.18px;
    opacity: 0.5;
    color: #fff;
    cursor: pointer;
`;

export const FooterWrapper = styled.footer`
    background-color: #000;
`

export const FooterNavList = styled.ul`
    padding-top: 20px;
    list-style: none;
    margin-bottom: 40px;
`

export const FooterNavListItem = styled.li`
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: normal;
    line-height: 18.9px;
    letter-spacing: -0.14px;
    opacity: 0.5;
    padding-bottom: 5px;

    &:hover {
        opacity: 1;
    }
`
export const FooterLogoImg = styled.img`
    max-width: 320px;
    width:100%;
`