import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Box from "@mui/material/Box";
import {
  LinkToEnrole,
  ServiceTypeLeft,
  ServiceTypeLeftContainer,
  ServiceTypeRight,
  ServiceTypeRightDetail,
  ServiceTypeWrapper,
  WhyUsText,
} from "./styles";
import { CustomButton } from "../../helper/components";
import CommunityImages from "./CommunityImages";
import TeachersImages from "./TeachersImages";
import OurTeamImages from "./OurTeamImages";
import PlatformImages from "./PlatformImages";

gsap.registerPlugin(ScrollTrigger);

interface WhyUsTextType {
  title: string;
  text: string;
}

const ServiceType: React.FC = () => {
  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.set(".photo", { opacity: 0, scale: 0.9 });

      const animation = gsap.to(".photo", {
        opacity: 1,
        scale: 1,
        duration: 0,
        stagger: 4,
      });

      ScrollTrigger.create({
        trigger: ".gallery",
        start: "top top+=20%",
        end: "bottom bottom+=40%",
        pin: ".rightblock",
        animation: animation,
        scrub: true,
        // markers: true,
      });
    });
    return () => ctx.revert();
  }, []);

  const WhyUsTexts: WhyUsTextType[] = [
    {
      title: "COMMUNITY",
      text: "We organize various events together with our graduates studying in many prestigious universities of the world. Our students have the opportunity to join a special club created together with Uzbek youth studying in top universities",
    },
    {
      title: "TOP RESULTS",
      text: "The maximum SAT scores of our graduates are up to 1560, and they study at the most prestigious universities in the world. The average SAT score of our alumni is 1450 points",
    },
    {
      title: "PROFESSIONAL TEACHERS",
      text: "The maximum SAT score of our teachers is up to 1570, and they conduct lessons using the methodology prepared by special SAT experts. The SAT result of our teachers is among the international Top 1% results, which means that your lessons will be conducted by professionals in their field",
    },
    {
      title: "PERSONALIZED PLATFORM",
      text: "The first Digital SAT Platform in Uzbekistan. On this platform, our students can do their homework, receive individual feedback about their shortcomings, and take weekly, midterm and final exams. Most importantly the platform prepares our students for the real exam environment",
    },
    {
      title: "SPECIAL MONITORING",
      text: "One of the main reasons why our graduates achieve high results is the strong Monitoring system. Every 2 weeks, our students take special midterm exam on our platform. According to the results of the exam, our mentors work with their shortcomings individually",
    },
    {
      title: "FREE ADMISSION CLASSES",
      text: "Special, 6 offline format 12-hour admission classes for application process to the world's top universities. In addition, the opportunity to use a special video course created by the Unlock team worth $1000, which explains the sequence of tasks that must be done while applying to the world's top universities",
    },
  ];
  return (
    <>
      <ServiceTypeWrapper>
        <Box className="gallery" sx={{ display: "flex" }}>
          <ServiceTypeLeft className="rightblock">
            <ServiceTypeLeftContainer
              sx={{
                "& .photo": {
                  position: "absolute",
                  width: "100%",
                  willChange: "transform, opacity",
                },
              }}
            >
              {WhyUsTexts.map((item, index) => (
                <Box key={index} className="photo">
                  <WhyUsText>
                    <div className="container">
                      <h4>{item.title}</h4>
                      <p className="whyustextp">{item.text}</p>
                      <LinkToEnrole to="enrole">
                        <CustomButton
                          fillColor="#000"
                          backgroundColor="#fff"
                          color="#000"
                        >
                          Enroll for a class
                        </CustomButton>
                      </LinkToEnrole>
                    </div>
                  </WhyUsText>
                </Box>
              ))}
            </ServiceTypeLeftContainer>
          </ServiceTypeLeft>
          <ServiceTypeRight>
            <ServiceTypeRightDetail>
              <CommunityImages />
            </ServiceTypeRightDetail>
            <ServiceTypeRightDetail>
              <OurTeamImages />
            </ServiceTypeRightDetail>
            <ServiceTypeRightDetail>
              <TeachersImages />
            </ServiceTypeRightDetail>
            <ServiceTypeRightDetail>
              <PlatformImages />
            </ServiceTypeRightDetail>
          </ServiceTypeRight>
        </Box>
      </ServiceTypeWrapper>
    </>
  );
};

export default ServiceType;
