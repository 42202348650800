import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { breakpoint_xl } from "../mixins";

export const Container = styled.div`
  width: 100%;
  padding: 0 18px;
  @media (min-width: ${breakpoint_xl}) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
  }
`;

export const TitleComponent = styled(Typography)`
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.2px;
`;
export const DescriptionComponent = styled(Typography)`
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.9px;
  letter-spacing: -0.28px;
  opacity: 0.5;
`;
export const FullHeight = styled(Box)`
  min-height: 100vh;
`;

export const SpinningIcon = styled(Box)`
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
