import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { breakpoint_md, breakpoint_sm } from "../../mixins";

export const SliderContainer = styled(Box)`
  overflow: hidden;
  padding-bottom: 60px;
  @media screen and (min-width: ${breakpoint_md}) {
    padding-bottom: 75px;
  }
`;

export const CourseBox = styled.div`
  width: 300px;
  min-height: 630px;
  border-radius: 20px;
  border: 3px solid #aa233b;
  background: #fff;
  padding: 17px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: ${breakpoint_sm}) {
    width: 340px;
  }
  .course-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding-top: 4px;
    padding-bottom: 30px;
    svg {
      fill: #000;
    }
    h5 {
      color: #000;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 28.5px;
      letter-spacing: -0.9px;
    }
  }
  ul.course-info-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 35px;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 14px;
      span {
        width: 20px;
        height: 20px;
        svg {
          path {
            fill: dark;
          }
        }
      }
      p {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.2px;
        letter-spacing: -0.48px;
      }
    }
  }
  :hover {
    background-color: #aa233b;
    .course-header {
      h5 {
        color: #fff;
      }
      svg {
        fill: #fff;
      }
    }
    ul.course-info-list {
      li {
        span {
          svg {
            color: #fff;
            fill: #fff;
            path {
              color: #fff;
            }
          }
        }
        p {
          color: #fff;
        }
      }
    }
  }
`;

export const TitleWrapper = styled(Box)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 20px;
`;
