import { EnroleButton,  WelcomeTextConatiner, WelcomeTextInfo, WelcomeWrapper} from "./styles";
import { Container, SpinningIcon } from "../../global_styles/styles";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

const Welcome: React.FC = () => {
  return (
    <WelcomeWrapper>
      <WelcomeTextConatiner>
        <Container>
          <WelcomeTextInfo>
            <h1>
              We're not just the premier choice for SAT prep: <br />
              <span>
                <TypeAnimation
                  sequence={["we're dream enablers.", 500, " we're dream enablers.", 500, "  we're dream enablers.", 500, ]}
                  repeat={Infinity}
                />
              </span>
            </h1>
            <Link to="enrole">
              <EnroleButton
                variant="contained"
                endIcon={
                  <SpinningIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none" >
                      <path d="M21.3619 4.34676L17.0509 0L10.059 7.04996L8.99732 2.98434L3.10992 4.5522L5.64075 14.2189L1.59786 13.116L0 19.0414L9.59786 21.6689L6.62735 24.6424L10.9276 29L17.9625 21.95L19.0349 26.0048L24.9222 24.4262L22.37 14.7703L26.4129 15.8624L28 9.92617L18.4236 7.33109L21.3834 4.34676H21.3619ZM16.3324 13.1376L21.3941 14.5108L16.3324 15.8732L17.6836 20.9769L13.9946 17.2356L10.2735 20.9661L11.6461 15.8624L6.6059 14.4784L11.6461 13.1376L10.3164 8.06636L13.9839 11.7644L17.6836 8.03393L16.3324 13.116V13.1376Z" fill="white" />
                    </svg>
                  </SpinningIcon>
                }
              >
                Enroll for a class
              </EnroleButton>
            </Link>
          </WelcomeTextInfo>
        </Container>
      </WelcomeTextConatiner>
    </WelcomeWrapper>
  );
};

export default Welcome;
