import { Container, DescriptionComponent, TitleComponent } from '../../global_styles/styles'
import { Box } from '@mui/material'
import { PersonolizedBox, TitleWrapper } from './styles'

const Personalized: React.FC = () => {
    return (
        <Container id='personal-platform'>
            <TitleWrapper>
                <TitleComponent>Personalized platform</TitleComponent>
                <DescriptionComponent>
                    Solve tests from our platform specifically created for our students  in order  to give max from our edu system
                </DescriptionComponent>
            </TitleWrapper>
            <PersonolizedBox>
                <Box>
                    <img loading="lazy" src={"/images/student-site-images/student-site-1.jpg"} alt="Student Site" />
                </Box>
                <Box>
                    <img loading="lazy" src={"/images/student-site-images/student-site-2.jpg"} alt="Student Site" />
                </Box>
            </PersonolizedBox>
        </Container>
    )
}

export default Personalized